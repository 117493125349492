html,
body {
    // height: 100%;
    margin: 0;
}


body {
    font-family: inherit!important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-800 {
    font-weight: 800 !important;
}
.fw-900 {
    font-weight: 900 !important;
}
.one_0001 {
    /* height: 100%; */
    background-image: linear-gradient(#ffe1b6, #ffe5bf);
    background-color: #fff2e9;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-lemon {
    background-image: linear-gradient(#ffe1b6, #ffe5bf);
    background-color: #fff2e9;
    background-repeat: no-repeat;
    background-size: cover;
}
.collase {
    margin-left: -10px;
}
.btn-maroon {
    background-color: #af0000 !important;
    color: #fff !important;
    text-decoration: none !important;
}
.bg-maroon {
    background-color: #af0000 !important;
}
.bg-body {
    background-image: linear-gradient(#ffe1b6, #ffe5bf);
    background-color: #fff2e9;
}
/* .navbar{
  background-image: linear-gradient(#ffe1b6,#ffe5bf);
  background-color: #fff2e9;
} */
/* .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #af0000!important;
} */
.form-control {
    font-size: 16px !important;
    height: auto !important;
}
.p-para {
    line-height: 1.5;
    font-weight: 500;
    font-size: 18px;
}
.site-footer {
    background-color: #af0000;
    padding: 45px 0 20px;
    font-size: 15px;
    line-height: 24px;
    color: #e2e2e2;
}
.site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5;
}
.site-footer hr.small {
    margin: 20px 0;
}
.site-footer h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px;
}
.site-footer a {
    color: #737373;
}
.site-footer a:hover {
    color: #3366cc;
    text-decoration: none;
}
.footer-links {
    padding-left: 0;
    list-style: none;
}
.footer-links li {
    display: block;
    margin-bottom: 8px;
}
.footer-links a {
    color: #e2e2e2;
    text-decoration: none;
    font-weight: 500;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
    color: #8db2fd;
    text-decoration: none;
}
.footer-links.inline li {
    display: inline-block;
}
.site-footer .social-icons {
    text-align: right;
}
.site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #e72828;
}
.copyright-text {
    margin: 0;
}
@media (max-width: 991px) {
    .site-footer [class^="col-"] {
        margin-bottom: 30px;
    }
}
@media (max-width: 767px) {
    .site-footer {
        padding-bottom: 0;
    }
    .site-footer .copyright-text,
    .site-footer .social-icons {
        text-align: center;
    }
}
.social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.social-icons li {
    display: inline-block;
    margin-bottom: 4px;
}
.social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px;
}
.social-icons a {
    background-color: #eceeef;
    color: #ffffff;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
    color: #fff;
    background-color: #29aafe;
}
.social-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px;
}
.social-icons a.facebook:hover {
    background-color: #3b5998;
}
.social-icons a.linkedin:hover {
    background-color: #007bb6;
}
.social-icons a.instagram:hover {
    background-color: #ea4c89;
}
@media (max-width: 767px) {
    .social-icons li.title {
        display: block;
        margin-right: 0;
        font-weight: 600;
    }
}
.pills.active,
.pills .show > .pills {
    color: #fff !important;
    background-color: #fff !important;
    border-radius: 0px !important;
    border-bottom: 4px solid #af0000 !important;
    color: #000 !important;
}
.google {
    width: 18px !important;
    margin-bottom: 2px !important;
}
.horizontalLine {
    height: 0px;
}
.lineDivider {
    margin-top: -32px;
}
.info-sidebar {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
.card-radio {
    display: flex;
    flex-flow: column;
}

.card-radio > div {
    flex: 1;
    padding: 1rem;
}

.radio-input input[type="radio"] {
    display: none;
}
.radio-input input[type="radio"]:not(:disabled) ~ label {
    cursor: pointer;
}
.radio-input input[type="radio"]:disabled ~ label {
    color: #bcc2bf;
    border-color: #bcc2bf;
    box-shadow: none;
}

.radio-input label {
    display: block;
    background: white;
    border: 1px solid #20df80;
    border-radius: 2px;
    text-align: center;
    box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
    position: relative;
    padding: 0.5rem;
}

.radio-input input[type="radio"]:checked + label {
    background: #20df80;
    color: white;
}
.radio-input input[type="radio"]:checked + label::after {
    color: #3d3f43;
    font-family: bootstrap-icons !important;
    border: 2px solid #1dc973;
    content: "\f633";
    font-size: 20px;
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 425px) {
    section {
        flex-direction: column;
    }
}

.radio-toolbar input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.radio-toolbar label {
    display: inline-block;
    background-color: #fff;
    padding: 4px 16px;
    font-size: 16px;
    border: 2px solid #afafaf;
    margin-right: 20px;
    border-radius: 10px;
}

.radio-toolbar label:hover {
    background-color: #af0000;
    color: #fff;
}

.radio-toolbar input[type="checkbox"]:focus + label {
    border: 2px solid #afafaf;
}

.radio-toolbar input[type="checkbox"]:checked + label {
    background-color: #af0000;
    border-color: #af0000;
    color: #fff;
}

.carousel-indicators li {
    width: 14px !important;
    height: 14px !important;
    background-color: #000 !important;
    border-radius: 50% !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 60px !important;
    height: 60px !important;
}
.pills.active,
.pills .show > .pills {
    color: #fff !important;
    background-color: #fff !important;
    border-radius: 0px !important;
    border-bottom: 4px solid #af0000 !important;
    color: #000 !important;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.bottom-0 {
    bottom: 0;
}
.start-0 {
    left: 0;
}
.text-muted {
    color: #6c757d !important;
}

    .review-video-popup{
        background: #f4ede4;
    }
    .review-video-popup-inner {
    padding: 50px 80px 50px 80px;
    }
    .whitebg-section{
        background-color: #fff;
        padding: 10px;
        max-height: 150px;
        overflow-y: auto;
    }
    .whitebg-section ul{
        list-style: none;
        padding:5px;
    }
    .whitebg-section ul li{
        text-align: left;
        padding-bottom: 10px;
    }
    .redrtext{
        color:#ff1b1b;
    }
    .downloadbtn {
        background-color: #f7c9c9;
        border: 0;
        width: 14rem;
        margin: 10px;
        text-decoration: none!important;
        padding: 10px 40px 10px 40px;
        font-weight: bold;
    }


    .requestbtn{
        background-color: #ffa500;
        border: 0;
        width: 10rem;
        padding: 10px 20px 10px 20px;
        margin: 10px;
        font-weight: bold;
    }
    .apprvbtn{
        background-color: #008000;
        border: 0;
        width: 10rem;
        padding: 10px 20px 10px 20px;
        margin: 10px;
        font-weight: bold;
    }

    .inputarea-section input{
        min-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid #fff;
        color:#000;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color:#fff;
    }
    .inputarea-section textarea{
        min-width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        border: 1px solid #fff;
        color:#000;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color:#fff;
    }
    .review-video-popup-inner {
        min-width: 600px;
    }
    .closebtn {
        text-align: right;
        position: relative;
        text-align: revert;
        top: 11px;
        padding: 7px;
        font-size: 22px;
        font-weight: bold;
        margin-right: 11px;
        float: right;
    }
    .contentsection ul li {
        line-height: 32px;
        font-size: 18px;
    }
    .bgdetailpopupbtn{
        background: #f7c9c9;
        font-size: 18px;
        color:#000;
    }
    .closebtndetailpopup {
        color: red;
        font-size: 25px;
        position: absolute;
        text-align: right;
        font-weight: 600;
        z-index: 99!important;
        margin-top: -59px;
        right: 59px;
        cursor: pointer;
    }

    .requestextbtn{
        background-color: #ffa500;
        border: 0;
        width: 10rem;
        padding: 8px 8px 8px 8px;
        margin: 10px;
        font-weight: 600; 
    }

    

//dropdwon

// body{
//     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
// }
// .custom_drop{
//     min-width: 250px;
// /* position: absolute;
// inset: 0px 0px auto auto;
// margin: 0px;
// transform: translate3d(0px, 25px, 0px); */
// padding: 0;
// }
// .bg-light{
//     background: #F8F9FA;
//     padding: 0.5rem!important;
//         border-bottom: 1px solid #dee2e6!important;
// }
// .cus-form-control {
// display: block;
// width: 100%;
// padding: 0.375rem 0.75rem;
// font-size: 16px;
// font-weight: 400;
// line-height: 1.5;
// color: #212529;
// background-color: #fff;
// background-clip: padding-box;
// border: 1px solid #ced4da;
// appearance: none;
// border-radius: 0.25rem;
// transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// box-shadow: none;
// }
// #autocom-box{
// max-height: 160px;overflow:auto;
// }
// .align-items-center {
// align-items: center!important;
// }

// .gap-2 {
// gap: 0.5rem!important;
// }
// .d-flex {
// display: flex!important;
// }
// .dropdown-item {
// display: block;
// width: 100%;
// padding: 0.25rem 1rem !important;
// clear: both;
// font-weight: 400;
// color: #212529;
// text-align: inherit;
// text-decoration: none;
// white-space: nowrap;
// background-color: transparent;
// border: 0;
// }
// .badge {
// display: inline-block;
// padding: 0.35em 0.65em;
// font-size: .75em;
// font-weight: 700;
// line-height: 1;
// color: #080c15;
// text-align: center;
// white-space: nowrap;
// vertical-align: baseline;
// border-radius: 0.25rem;
// background: transparent;
// }
// .border-dark {
// border-color: #212529!important;
// }
// a:hover{
// text-decoration: none;
// }
// .border {
// border: 1px solid #212529!important;
// }
// .dropdown-item:focus, .dropdown-item:hover {
// color: #1e2125 !important;
// background-color: #e9ecef !important;
// }
// hr:not([size]) {
// height: 1px;
// }
// li.bg-light.py-1 {
// padding: 0.25rem 0 !important;

// }
// .my-0 {
// margin-top: 0!important;
// margin-bottom: 0!important;
// }
// .dropdown-divider {
// height: 0;
// margin: 0.5rem 0;
// overflow: hidden;
// border-top: 1px solid rgba(0,0,0,.15);
// }


@media (min-width: 960px){
.md\:min-w-100 {
    min-width: 15rem !important;
    width: 15rem !important;
}
}
